import { Suspense, lazy, useContext } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import WorkFlow from '../pages/dashboard/WorkFlow';
import Agents from '../pages/dashboard/Agents';
import AddNewAgent from '../sections/@dashboard/agent/AddNewAgent';
import AddNewChannel from '../sections/@dashboard/channel/AddNewChannel';
import AddNewDepartment from '../sections/@dashboard/department/AddNewDepartment';
import AddNewAuditor from '../sections/@dashboard/auditor/AddNewAuditor';
import AddNewTemplate from '../sections/@dashboard/template/AddNewTemplate';
import Auditors from '../pages/dashboard/Auditors';
import Templates from '../pages/dashboard/Templates';
import Campaigns from '../pages/dashboard/Campaigns';
import Analytics from '../pages/dashboard/Analytics';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import CRMLayout from 'src/layouts/crm';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
import Channels from '../pages/dashboard/Channels';
import Reports from '../pages/dashboard/Reports';
import Departments from '../pages/dashboard/Departments';
import { ChannelContextProvider } from '../contexts/ChannelsContext';
import BusinessSettings from '../pages/dashboard/BusinessSettings';
import WebhookAPI from '../pages/dashboard/WebhookAPI';
import Organization from '../pages/dashboard/Organization';
import { useSelector } from 'react-redux';
import { AuthContext } from 'src/contexts/JWTContext';
import WhatsAppTemplates from 'src/pages/dashboard/WhatsAppTemplates';
import WhatsAppTemplateHomeSceen from 'src/pages/dashboard/WhatsAppTemplateHomeSceen';
import WaFailedMessages from '../pages/dashboard/WaFailedMessages';
import ConversationReport from 'src/pages/dashboard/ConversationReport';
import ChatHistory from 'src/pages/dashboard/ChatHistory';


export const authorizedRoutes = {
  SUPER_ADMIN: [
    '/app/general/dashboard',
    '/app/general/organization',
    '/app/general/workflow',
    '/app/general/channels',
    '/app/general/channels/new',
    '/app/general/channels/edit/:id',
    '/app/general/reports',
    '/app/management/departments',
    '/app/management/departments/new',
    '/app/management/departments/update/:id',
    '/app/management/agents',
    '/app/management/agents/new',
    '/app/management/agents/edit/:id',
    '/app/management/auditors',
    '/app/management/auditors/new',
    '/app/management/auditors/update/:id',
    '/app/management/template/new',
    '/app/management/templates',
    '/app/management/campaigns',
    '/app/management/business_settings',
    '/app/management/webhook_api',
    '/app/management/wa_templates',
    '/app/management/wa_templates_new',
    '/app/general/analytics',
    '/app/general/WaFailedMessages',
    '/app/general/conversationreport',
    '/app/general/chathistory',
    '/crm/contacts',
    '/crm/channel',
    '/crm/groups',
  ],
  ADMIN: [
    '/app/general/dashboard',
    '/app/general/workflow',
    '/app/general/channels',
    '/app/general/channels/new',
    '/app/general/channels/edit/:id',
    '/app/general/reports',
    '/app/general/analytics',
    '/app/general/WaFailedMessages',
    '/app/general/conversationreport',
    '/app/general/chathistory',
    '/app/management/departments',
    '/app/management/departments/new',
    '/app/management/departments/update/:id',
    '/app/management/agents',
    '/app/management/agents/new',
    '/app/management/agents/edit/:id',
    '/app/management/auditors',
    '/app/management/auditors/new',
    '/app/management/auditors/update/:id',
    '/app/management/template/new',
    '/app/management/templates',
    '/app/management/campaigns',
    '/app/management/business_settings',
    '/app/management/webhook_api',
    '/app/management/wa_templates',
    '/app/management/wa_templates_new',
    '/crm/contacts',
    '/crm/channel',
    '/crm/groups'
  ],
  AGENT :[
    '/app/management/chat',
  ],
  AUDITOR :[
    '/app/management/agents',
    '/app/management/agents/new',
    '/app/management/agents/edit/:id',
    '/app/management/chat',
  ],
  // Add more roles and their authorized routes
};
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'app',
      element: (
        <AuthGuard authorizedRoutes={authorizedRoutes} >
          <DashboardLayout />
         </AuthGuard>
      ),
      children: [
        { path: 'general/dashboard', element: <GeneralApp/> },
        { path: 'general/organization', element: <Organization /> },
        { path: 'general/workflow', element: <WorkFlow /> },
        { path: 'general/channels', element:<ChannelContextProvider> <Channels /> </ChannelContextProvider>},
        { path: 'general/channels/new', element: <AddNewChannel isEdit={false} /> },
        { path: 'general/analytics', element: <Analytics /> },
        { path: 'general/WaFailedMessages', element: <WaFailedMessages /> },
        { path: 'general/conversationreport', element: <ConversationReport /> },
        { path: 'general/chathistory', element: <ChatHistory /> },


        // eslint-disable-next-line react/jsx-boolean-value
        { path: 'general/channels/edit/:id', element: <AddNewChannel isEdit={true} /> },
        { path: 'general/reports', element: <Reports /> },
        { path: 'management/departments', element: <Departments/> },
        { path: 'management/departments/new', element: <AddNewDepartment isEdit={false} /> },
        // eslint-disable-next-line react/jsx-boolean-value
        { path: 'management/departments/update/:id', element: <AddNewDepartment isEdit={true} /> },
        { path: 'management/agents', element: <Agents /> },
        // { path: 'management/agents/new', element: <AddNewAgent /> },
        { path: 'management/agents/new', element: <AddNewAgent isEdit={false} /> },
        // eslint-disable-next-line react/jsx-boolean-value
        { path: 'management/agents/edit/:id', element: <AddNewAgent isEdit={true} /> },
        { path: 'management/auditors', element: <Auditors /> },
        { path: 'management/auditors/new', element: <AddNewAuditor isEdit={false} /> },
        // eslint-disable-next-line react/jsx-boolean-value
        { path: 'management/auditors/update/:id', element: <AddNewAuditor isEdit={true} /> },
        { path: 'management/template/new', element: <AddNewTemplate isEdit={false} /> },
        { path: 'management/templates', element: <Templates /> },
        { path: 'management/campaigns', element: <Campaigns /> },
        { path: 'management/business_settings', element: <BusinessSettings /> },
        { path: 'management/webhook_api', element: <WebhookAPI /> },
        { path: 'management/chat', element: <Chat /> },
        { path: 'management/wa_templates', element: <WhatsAppTemplateHomeSceen /> },
        { path: 'management/wa_templates_new', element: <WhatsAppTemplates /> },


        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ],
        },

        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
       
      ],
    },
    {
      path: 'crm',
      element: (
        <AuthGuard authorizedRoutes={authorizedRoutes} >
          <CRMLayout />
         </AuthGuard>
      ),
      children:[
        {path: '/crm/contacts', element: <Users/>},
        {path: '/crm/groups', element: <Groups/>},
      ]

    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      // element: <MainLayout />,
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),    
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));


// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const Chat = Loadable(lazy(() => import('../sections/@dashboard/chat')));

// MAIN

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// CRM

const Users = Loadable(lazy(()=> import('../pages/crm/Users')))
const Groups = Loadable(lazy(()=>import('../pages/crm/Groups')))