import { TableCell, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';

FailedMessageRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};

export default function FailedMessageRow({ row, selected }) {
  const { message_id, from, to, message, dateTime, remarks } = row;

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {message_id}
          </Typography>
        </TableCell>
        <TableCell align="center">{from}</TableCell>
        <TableCell align="center">{to}</TableCell>
        <TableCell align="center">{message?.data?.[0]?.text}</TableCell>
        <TableCell align="center">{dateTime}</TableCell>
        <TableCell align="center">{remarks}</TableCell>
      </TableRow>
    </>
  );
}
