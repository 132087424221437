// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_APP = '/app';
const ROOTS_CRM ='/crm'
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_APP = {
  root: ROOTS_APP,
  general: {
    dashboard: path(ROOTS_APP, '/general/dashboard'),
    workflow: path(ROOTS_APP, '/general/workflow'),
    channels: path(ROOTS_APP, '/general/channels'),
    updateChannel: (id) => path(ROOTS_APP, `/general/channels/update/${id}`),
    reports: path(ROOTS_APP, '/general/reports'),
    organization: path(ROOTS_APP, '/general/organization'),
    analytics: path(ROOTS_APP, '/general/analytics'),
    wafailed:  path(ROOTS_APP, '/general/WaFailedMessages'),
    conversation: path(ROOTS_APP, '/general/conversationreport'),
    chathistory: path(ROOTS_APP, '/general/chathistory'),
  },
  management: {
    departments: path(ROOTS_APP, '/management/departments'),
    newDepartment: path(ROOTS_APP, '/management/departments/new'),
    updateDepartment: (id) => path(ROOTS_APP, `/management/departments/update/${id}`),
    agents: path(ROOTS_APP, '/management/agents'),
    newAgent: path(ROOTS_APP, '/management/agents/new'),
    // updateAgent: (id) => path(ROOTS_APP, `/management/agents/${id}`),
    updateAgent: (id) => path(ROOTS_APP, `/management/agents/edit/${id}`),
    auditors:  path(ROOTS_APP, '/management/auditors'),
    newAuditor: path(ROOTS_APP, '/management/auditors/new'),
    updateAuditor: (id) => path(ROOTS_APP, `/management/auditors/update/${id}`),
    templates:  path(ROOTS_APP, '/management/templates'),
    newTemplate: path(ROOTS_APP, '/management/template/new'),
    campaigns:  path(ROOTS_APP, '/management/campaigns'),
    businessSettings: path(ROOTS_APP, '/management/business_settings'),
    webhookAPI: path(ROOTS_APP, '/management/webhook_api'),
    chat: path(ROOTS_APP, '/management/chat'),
    waTemplates:  path(ROOTS_APP, '/management/wa_templates'),
    waTemplatesNew:  path(ROOTS_APP, '/management/wa_templates_new'),


  },
  user: {
    root: path(ROOTS_APP, '/user'),
    new: path(ROOTS_APP, '/user/new'),
    list: path(ROOTS_APP, '/user/list'),
    cards: path(ROOTS_APP, '/user/cards'),
    profile: path(ROOTS_APP, '/user/profile'),
    account: path(ROOTS_APP, '/user/account'),
    edit: (name) => path(ROOTS_APP, `/user/${name}/edit`),
    demoEdit: path(ROOTS_APP, `/user/reece-chung/edit`),
  },
};

export const PATH_CRM ={
  root:ROOTS_CRM,
 
  users: path(ROOTS_CRM, '/contacts'),
  

  groups: path(ROOTS_CRM, '/groups'),
 
  
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
